import React from 'react';
import { Helmet } from 'react-helmet';

type HelmetProps = {
  title: string,
  description: string,
  keywords: string,
  author?: string,
  canonicalUrl?: string,
  ogTitle?: string,
  ogDescription?: string,
  ogImage?: string,
  ogUrl?: string,
  twitterTitle?: string,
  twitterDescription?: string,
  twitterImage?: string,
  twitterCard?: string,
  twitterSite?: string,
}

const SEOHelmet = ({
  title,
  description,
  keywords,
  author = "Syrux",
  canonicalUrl,
  ogTitle,
  ogDescription,
  ogImage,
  ogUrl,
  twitterTitle,
  twitterDescription,
  twitterImage,
  twitterCard,
  twitterSite,
}: HelmetProps) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      {keywords && <meta name="keywords" content={keywords} />}
      {author && <meta name="author" content={author} />}
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      {ogTitle && <meta property="og:title" content={ogTitle} />}
      {ogDescription && <meta property="og:description" content={ogDescription} />}
      {ogImage && <meta property="og:image" content={ogImage} />}
      {ogUrl && <meta property="og:url" content={ogUrl} />}
      {twitterTitle && <meta name="twitter:title" content={twitterTitle} />}
      {twitterDescription && <meta name="twitter:description" content={twitterDescription} />}
      {twitterImage && <meta name="twitter:image" content={twitterImage} />}
      {twitterCard && <meta name="twitter:card" content={twitterCard} />}
      {twitterSite && <meta name="twitter:site" content={twitterSite} />}
    </Helmet>
  );
};

export default SEOHelmet;
