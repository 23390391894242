import React from 'react'
import Button from 'components/Button'
import Auth from 'utils/Auth';
import useUser from 'hooks/useUser';

type LogoutProps = {
  handleButtonClick: (val?: boolean) => void;
}

const Index: React.FC<LogoutProps> = ({ handleButtonClick }) => {
  const { initials } = useUser()  

  return (
    <div className={styles.section}>
      <div className={styles.box}>
        <div className={styles.initials}>{initials}</div>
        <div>
          <h1 className='text-lg font-bold'>Log out of account?</h1>
          <p className='text-xs md:text-sm md:w-2/3 mt-5 mb-10 mx-auto'>Are you sure you want to logout? You can't get notifications if you do.</p>
          
          <div className='flex items-center justify-center flex-col md:flex-row gap-5 '>
            <Button handleClick={() => handleButtonClick(false)} classProp='border-[transparent] text-dashboard-black' isSecondary>Stay on app</Button>
            <Button handleClick={() => Auth.logOut()}>Logout</Button>
          </div>
        </div>
      </div>
    </div>
  )
}

const styles = {
  section: 'flex items-center justify-center',
  box: 'relative drop-shadow-xl md:p-20 p-10 bg-white rounded-xl w-full text-center mt-[5%]',
  initials: 'p-3 rounded-full bg-primary border-2 border-primary transition duration-300 hover:scale-110 text-white text-mlg font-bold absolute md:left-[45%] left-[40%] -top-6',
}

export default Index;