import React, { useEffect } from 'react';
import RouteComponent from './routes/Route';
import Aos from 'aos';
import { QueryClient, QueryClientProvider } from 'react-query';

const App: React.FC = () => {
  const queryClient = new QueryClient();

  useEffect(() => {
    Aos.init({ duration: 750, once: true });
    Aos.refresh();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <div id="app" className="App">
        <RouteComponent />
      </div>
    </QueryClientProvider>
  );
};

export default App;
