import { useState, useEffect } from 'react'
import User from 'utils/User';

type UseUserType = {
    first_name: string,
    last_name: string,
    account_type: string,
}

const useUser = () => {
    const [user, setUser] = useState<UseUserType>({
        first_name: '',
        last_name: '',
        account_type: '',
    });

    useEffect(() => {
        const userData = User.getUser();

        if(userData) setUser(userData)
    }, [])

    const name = `${user?.first_name} ${user?.last_name}`
    const initials = `${name.charAt(0)}${name.split(' ')[1].charAt(0)}`
    const type = user?.account_type;

    const data = { name, initials, type }

    return data
}

export default useUser