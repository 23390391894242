import React, { Suspense, lazy } from 'react';
import { BounceLoader } from 'react-spinners';
import { CONTACT, COURSES, DASHBOARD, EDITCOURSES, FAQ, FORGOTPASSWORD, HOME, REMOVECOURSE, LOGIN, NEWACTIVITY, NEWCOURSE, ONBOARD, PROFILE, RESETPASSWORD, SECURITY, SIGNUP, SUPPORT, VERIFYACCOUNT, PRIVACYPOLICY, ABOUTCOURSES } from 'constants/path'
import { forgotPasswordTexts, LoginTexts, signUpTexts } from 'data';

interface routeInterface {
  path: string;
  component: React.FC;
  quotes?: string[]
}

interface routeProtectedInterface {
  path: string;
  component: React.FC;
  title?: string;
}

const load = (Component: React.FC) => (props: object) =>
(
  <Suspense
    fallback={
      <div className="w-[100%] my-[20%] flex justify-center items-center">
        <BounceLoader loading color="#0052CC" />
      </div>
    }
  >
    <Component {...props} />
  </Suspense>
);

const Home = load(lazy(() => import('pages/Home')));
const Faq = load(lazy(() => import('pages/Faq')));
const Contact = load(lazy(() => import('pages/Contact')));
const PrivacyPolicy = load(lazy(() => import('pages/PrivacyPolicy')));

const Login = load(lazy(() => import('pages/Auth/Login')));
const SignUp = load(lazy(() => import('pages/Auth/SignUp')));
const OnBoard = load(lazy(() => import('pages/Auth/components/SignUp/OnBoard')));

const ForgotPassword = load(lazy(() => import('pages/Auth/ForgotPassword')));
const ResetPassword = load(lazy(() => import('pages/Auth/components/ForgotPassword/ResetPassword')));

const Dashboard = load(lazy(() => import('pages/Dashboard')));
const Courses = load(lazy(() => import('pages/Courses')));
const EditCourses = load(lazy(() => import('pages/Courses/components/EditCourse')));
const AboutCourse = load(lazy(() => import('pages/Courses/components/MobileAboutCourses')));
const NewCourse = load(lazy(() => import('pages/Courses/components/NewCourse')));
const NewActivity = load(lazy(() => import('pages/Courses/components/NewActivity')));
const RemoveCourse = load(lazy(() => import('pages/Courses/components/RemoveCourse')));
const Profile = load(lazy(() => import('pages/Profile')));

const authProtectedRoutes: routeProtectedInterface[] = [
  { path: DASHBOARD, component: Dashboard, title: "Dashboard" },
  { path: COURSES, component: Courses, title: "Courses" },
  { path: EDITCOURSES, component: EditCourses, title: "Edit Courses" },
  { path: ABOUTCOURSES, component: AboutCourse, title: "About Course" },
  { path: NEWCOURSE, component: NewCourse, title: "Add New Course" },
  { path: NEWACTIVITY, component: NewActivity, title: "Add New Activity" },
  { path: REMOVECOURSE, component: RemoveCourse, title: "Remove Course" },
  { path: PROFILE, component: Profile, title: "My Profile" },
  { path: SECURITY, component: Home },
  { path: VERIFYACCOUNT, component: Home },
  { path: SUPPORT, component: Home },
];


const publicRoutes: routeInterface[] = [
  { path: HOME, component: Home },
  { path: FAQ, component: Faq },
  { path: CONTACT, component: Contact },
  { path: PRIVACYPOLICY, component: PrivacyPolicy },
];

const authRoutes: routeInterface[] = [
  { path: LOGIN, component: Login, quotes: LoginTexts },
  { path: SIGNUP, component: SignUp, quotes: signUpTexts },
  { path: ONBOARD, component: OnBoard, quotes: signUpTexts },
  { path: FORGOTPASSWORD, component: ForgotPassword, quotes: forgotPasswordTexts },
  { path: RESETPASSWORD, component: ResetPassword, quotes: forgotPasswordTexts },
];

export { authProtectedRoutes, publicRoutes, authRoutes };
