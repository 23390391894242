import { CONTACT, COURSES, DASHBOARD, FAQ, PROFILE } from 'constants/path'
import {ReactComponent as DashboardIcon} from 'assets/icons/dashboard/dashboardIcon.svg'
import {ReactComponent as CoursesIcon} from 'assets/icons/dashboard/coursesIcon.svg'
// import {ReactComponent as ActivitiesIcon} from 'assets/icons/dashboard/coursesIcon.svg'
import {ReactComponent as ProfileIcon} from 'assets/icons/dashboard/profileIcon.svg'


import facebook from 'assets/icons/socials/facebook.svg'
import twitter from 'assets/icons/socials/twitter.svg'
import instagram from 'assets/icons/socials/instagram.svg'
import linkedin from 'assets/icons/socials/linkedin.svg'

export const notificationData = [
  {
      status: "cancelled",
      title: "Reminder // Test",
      notification: "Csc 431 Test is tomorrow at 4:30",
      time: "10m ago"
  },
  {
      status: "holding",
      title: "Reminder // Test",
      notification: "Csc 421 Test is tomorrow at 4:30",
      time: "10m ago"
  },
  {
      status: "holding",
      title: "Reminder // Test",
      notification: "Csc 401 Test is tomorrow at 4:30",
      time: "10m ago"
  },
  {
      status: "holding",
      title: "Reminder // Test",
      notification: "Csc 413 Test is tomorrow at 4:30",
      time: "10m ago"
  },
  {
      status: "holding",
      title: "Reminder // Test",
      notification: "Csc 415 Test is tomorrow at 4:30",
      time: "10m ago"
  },
]

export const links = [
  { text: 'FAQs', route:FAQ },
  { text: 'Contact Us', route: CONTACT },
];

export const dashboardLinks = [
  { icon: DashboardIcon, label: "Dashboard", route: DASHBOARD },
  { icon: CoursesIcon, label: "Courses", route: COURSES },
  // { icon: ActivitiesIcon, label: "Activities", route: ACTIVITIES },
  { icon: ProfileIcon, label: "Profile", route: PROFILE },
]

export const levels = ['100', '200', '300', '400', '500'];

export const prices = [
  {
    title: 'Freebbie',
    baseColor: '#333333',
    price: 'Free Forever',
    duration: '',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris mi, enim sociis turpis et dictumst sit pulvinar a',
    features: [
      {
        isChecked: true,
        feature: 'Lorem1 ipsum dolor ',
      },
      {
        isChecked: true,
        feature: 'Lorem2 ipsum dolor ',
      },
      {
        isChecked: true,
        feature: 'Lorem3 ipsum dolor ',
      },
      {
        isChecked: false,
        feature: 'Lorem4 ipsum dolor ',
      },
      {
        isChecked: false,
        feature: 'Lorem5 ipsum dolor ',
      },
      {
        isChecked: false,
        feature: 'Lorem6 ipsum dolor ',
      },
    ],
  },
  {
    title: 'Mini',
    baseColor: '#0052CC',
    price: '#500',
    duration: '/month',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris mi, enim sociis turpis et dictumst sit pulvinar a',
    features: [
      {
        isChecked: true,
        feature: 'Lorem1 ipsum dolor ',
      },
      {
        isChecked: true,
        feature: 'Lorem2 ipsum dolor ',
      },
      {
        isChecked: true,
        feature: 'Lorem3 ipsum dolor ',
      },
      {
        isChecked: true,
        feature: 'Lorem4 ipsum dolor ',
      },
      {
        isChecked: false,
        feature: 'Lorem5 ipsum dolor ',
      },
      {
        isChecked: false,
        feature: 'Lorem6 ipsum dolor ',
      },
    ],
  },
  {
    title: 'Maxi',
    baseColor: '#333333',
    price: '#1700',
    duration: '/semester',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris mi, enim sociis turpis et dictumst sit pulvinar a',
    features: [
      {
        isChecked: true,
        feature: 'Lorem1 ipsum dolor ',
      },
      {
        isChecked: true,
        feature: 'Lorem2 ipsum dolor ',
      },
      {
        isChecked: true,
        feature: 'Lorem3 ipsum dolor ',
      },
      {
        isChecked: true,
        feature: 'Lorem5 ipsum dolor ',
      },
      {
        isChecked: true,
        feature: 'Lorem4 ipsum dolor ',
      },
      {
        isChecked: true,
        feature: 'Lorem6 ipsum dolor ',
      },
    ],
  },
];

export const testimonials = [
  {
    message:
      "Daple has been a game-changer for me as a student. The instant notifications on timetable changes and assignment deadlines have helped me stay on track and never miss a class or submission. It's like having a personal assistant keeping me organized. I highly recommend Daple to all my fellow students!",
    user: 'Tolulope',
    role: 'Student',
  },
  {
    message:
      "Managing my courses has become so much easier with Daple. I love how I can track all my classes and receive reminders on important updates, all in one place. It saves me time and eliminates the need to scroll through numerous messages or search for information on whatsapp. Daple has definitely made my student life more efficient and stress-free.",
    user: 'Mark',
    role: 'Class Rep',
  },
  {
    message:
      "As a class rep, Daple has been a lifesaver. It provides a seamless way for me to communicate important information to my classmates. I can easily send updates on class schedules, venue changes, or upcoming deadlines, ensuring that everyone is informed. Daple has transformed the way we stay connected and organized as a class.",
    user: 'Hassan',
    role: 'Class Rep',
  },
  {
    message:
      "Daple has revolutionized how I manage my time as a student. The reminders on assignment deadlines keep me accountable and prevent any last-minute rush. I appreciate how user-friendly the app is, allowing me to navigate effortlessly and access all the information I need. It's a must-have tool for any student looking to stay organized and stay ahead.",
    user: 'Amaka',
    role: 'Student',
  },
  {
    message:
      "I can't imagine my student life without Daple. It has become my go-to app for staying on top of my classes and assignments. The instant notifications on timetable changes have saved me from missing important lectures, and the reminders on deadlines have ensured I never submit my work late. Daple has truly transformed the way I manage my academic responsibilities.",
    user: 'Emma',
    role: 'Student',
  },
];

export const faqs = [
  {
    question: "What happens when the course representative changes?",
    answer:
      'When a course representative changes, their access to the course on Daple will be removed. The new representative will need to be added to the course and given access by the course administrator. Any updates, such as changes to the timetable or deadlines, that were made by the previous representative will still be visible on the course page, but the new representative will be responsible for making future updates. It is important to ensure that the course administrator is informed of any changes to the course representative so that they can update the access and ensure a smooth transition.',
  },
  {
    question: "Is the app available offline?",
    answer:
      'No, the app requires an internet connection to function properly. Daple relies on real-time data updates and notifications, which require an active internet connection to provide you with the most up-to-date information. We understand the importance of offline access, and while it is not currently available, we assure you that we are actively exploring the possibility of introducing offline functionality in a future update.',
  },
  {
    question: "What happens to my schedule if I don't have an internet connection?",
    answer:
      "If you don't have an internet connection, Daple may not be able to fetch real-time updates or synchronize changes to your schedule. It's always recommended to have an internet connection to ensure you have the most up-to-date information regarding your schedule and any modifications that may have occurred.",
  },
  {
    question: "Is my personal information secure with Daple?",
    answer:
      'Absolutely! At Daple, we take the security and privacy of your personal information very seriously. We have implemented robust security measures to ensure the confidentiality, integrity, and availability of your data. We also adhere to strict data protection regulations and privacy policies to safeguard your information. ',
  },
  {
    question: "How do I troubleshoot any issues with the app?",
    answer:
      `If you encounter any issues or have questions about the app, we're here to help! We have a dedicated support team ready to assist you with any troubleshooting steps you may need. Here's how you can get the support you need:

      1. Check the Help Center: Visit our Help Center on our website. It provides detailed guides, FAQs, and troubleshooting tips to address common issues.
      2. Contact Support: If you can't find the solution in the Help Center, reach out to our support team directly. You can typically find a "Contact Us" or "Support" option within the app. Provide a detailed description of the issue you're experiencing, and our team will respond as soon as possible to assist you.
      3. Follow us on social media: Join our vibrant community of users on various social media platforms. Here, you can connect with other users, share experiences, and seek advice. Our community is often helpful in providing solutions or workarounds for various issues.
      4. Updates and Notifications: Keep your app updated to ensure you have the latest features and bug fixes. We regularly release updates to improve the app's performance and address any`,
  },
  {
    question: "Is Daple free?",
    answer:
      'Yes, Daple is completely free to use. There are no hidden charges or subscription fees for accessing the core features of the app. We believe in providing an accessible and valuable tool for students to manage their class activities without any financial burden. Enjoy the full benefits of Daple without worrying about costs.',
  },
  {
    question: "How are class rep’s verified?",
    answer:
      'Class reps are verified through a verification process implemented by Daple. This verification process helps maintain the integrity and reliability of the class rep functionality within Daple.',
  },
  {
    question: "How do I report a class rep",
    answer:
      `If you encounter any issues or concerns with a class rep on Daple, we take them seriously and encourage you to report them. To report a class rep, please follow these steps:

      Open the Daple app and navigate to the profile or settings section.Look for the "Report" or "Contact Support" option. 
      
      Provide detailed information about the issue you're facing with the class rep, including any relevant evidence or screenshots.Submit your report.
      
      Our team will promptly review and investigate the reported issue. We strive to maintain a safe and positive environment within our community, and we appreciate your help in ensuring the best experience for all users.
      
      Please note that false or malicious reports may result in account restrictions or actions taken against the reporting user. We kindly request that you provide accurate and honest information when reporting a class rep.`,
  },
];

export const socials = [
  {
    name: "Facebook",
    link: process.env.REACT_APP_DAPLE_FACEBOOK,
    icon: facebook,
  },
  {
    name: "Twitter",
    link: process.env.REACT_APP_DAPLE_TWITTER,
    icon: twitter,
  },
  {
    name: "Instagram",
    link: process.env.REACT_APP_DAPLE_INSTAGRAM,
    icon: instagram,
  },
  {
    name: "LinkedIn",
    link: process.env.REACT_APP_DAPLE_LINKEDIN,
    icon: linkedin,
  },
]


export const signUpTexts = [
  "Never miss an important class or assignment again! With Daple, staying on top of your schedule has never been easier. Join the community of successful students today.",
  "Experience the benefits of staying organized and on top of your schedule with Daple!",
  "Get organized with instant updates and reminders on changes to your timetable and deadlines.",
]

export const LoginTexts = [
  "Glad to see you back! Let's jump right in to doing things the Daple way, the smarter way.",
  "Welcome back! We're glad to have you here again. Let's get started by logging in and staying organized with your class schedule and reminders.",
]

export const forgotPasswordTexts = [
  "Don't worry, forgetting your password happens to the best of us. Simply click 'Forgot Password' and follow the prompts to reset it. Contact us, if you need further assistance. ",
  "You can now access your account again with a new password. Resetting your password ensures that your information stays secure. ",
  "Great news! You now have access to your account again. We're glad we could help get you back on track.",
]