import React, { useState } from "react";
import Button from "components/Button";
import { motion } from "framer-motion";
import { Link, NavLink } from "react-router-dom";
import { links } from "data";
import { DASHBOARD, HOME, LOGIN } from "constants/path";

import logo from "assets/images/logo.svg";
import menu from "assets/icons/menu.svg";
import Auth from "utils/Auth";

const Header: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (value: boolean) => {
    setIsOpen(value);
    if (value) document.querySelector("body")!.style.overflow = "hidden";
    else document.querySelector("body")!.style.overflow = "";
  };

  return (
    <nav className={styles.section}>
      <div className={styles.inner}>
        <div>
          <Link to={HOME}>
            <img className={styles.logo} src={logo} alt="logo" />
          </Link>
        </div>
        <div className={styles.links}>
          {links.map((link) => (
            <NavLink className={({ isActive }) => (isActive ? styles.activeLink : "")} key={link.text} to={link.route}>
              <button onClick={() => setIsOpen(false)} className={styles.mLink}>
                {link.text}
              </button>
            </NavLink>
          ))}
        </div>
        <motion.div whileTap={{ scale: 0.9 }} transition={{ type: "spring", stiffness: 400, damping: 17 }}>
          {Auth.isAuthenticated() ? (
            <Link to={DASHBOARD}>
              <Button classProp={styles.btn}>Dashboard</Button>
            </Link>
          ) : (
            <Link to={LOGIN}>
              <Button classProp={styles.btn}>Login</Button>
            </Link>
          )}
        </motion.div>

        <div className="lg:hidden">
          <img onClick={() => handleClick(!isOpen)} className="cursor-pointer" src={menu} alt="menu" />
          <div className={`${styles.mLinkContainer} ${isOpen ? "" : "hidden"}`}>
            <div className={styles.mLinks}>
              {links.map((link) => (
                <NavLink
                  className={({ isActive }) => (isActive ? styles.activeLink : "")}
                  key={link.text}
                  to={link.route}
                >
                  <button onClick={() => handleClick(false)} className={styles.mLink}>
                    {link.text}
                  </button>
                </NavLink>
              ))}
              <motion.div whileTap={{ scale: 0.9 }} transition={{ type: "spring", stiffness: 400, damping: 17 }}>
                {Auth.isAuthenticated() ? (
                  <Link to={DASHBOARD}>
                    <Button classProp="py-3 px-12" handleClick={() => handleClick(false)}>
                      Dashboard
                    </Button>
                  </Link>
                ) : (
                  <Link to={LOGIN}>
                    <Button classProp="py-3 px-12" handleClick={() => handleClick(false)}>
                      Login
                    </Button>
                  </Link>
                )}
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

const styles = {
  section:
    "w-full filter backdrop-blur-lg transition-all duration-300 ease-in-out fixed z-[100] md:pb-5 md:pt-3 pt-2 bg-secondary/50",
  inner: "max-w-full mx-auto flex justify-between items-center md:px-20 px-10",
  logo: "pb-2 md:w-auto w-20",
  links: "hidden w-50 lg:flex justify-between lg:gap-5",
  link: "cursor-pointer",
  searchInput: "hidden lg:flex px-3 rounded-lg bg-white drop-shadow-md",
  input: "outline-none p-2",
  btn: "py-3 px-12 lg:block hidden",
  mLinkContainer:
    "rounded transition linear duration-[0.3s] drop-shadow-lg fixed top-30 left-0 bg-secondary z-20 p-5 w-full",
  mLinks: "flex flex-col gap-6 text-center",
  mLink: "cursor-pointer py-2 px-3 transition linear duration-[0.3s] hover:text-primary hover:transition font-[600]",
  activeLink: "text-primary font-bold",
};

export default Header;
