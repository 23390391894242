import React from "react";
import { ModalType, NotificationProps } from "types/Types";
import NotficationCard from "./NotficationCard";
import { GetNotifications } from "services/Students";
import { PuffLoader } from 'react-spinners';


import { ReactComponent as CloseIcon } from 'assets/icons/dashboard/closeIcon.svg'
import Img from 'assets/illustrations/sad.svg'


const NotificationModal = ({ showModal, handleButtonClick }: ModalType) => {
    const { data, isLoading } = GetNotifications()

    return (
        <>
            {showModal ? (
                <div className="w-full h-full">
                    <div className="fixed inset-0 z-50 overflow-y-auto bg-red filter backdrop-blur-sm">
                        <div className="fixed inset-0 w-full h-full bg-black opacity-60"></div>
                        <div className="flex items-center md:min-h-screen px-4 py-8">
                            <div className="relative md:w-4/5 lg:w-3/5 2xl:w-1/3 w-full py-5 px-5 md:px-10 mx-auto bg-secondary rounded-3xl shadow-lg">
                                <div className="my-3 relative">
                                    <div className="absolute filter backdrop-blur-lg w-full bg-secondary flex justify-between items-center pb-5 mb-10 border-b-2 border-[#BEC3C9]">
                                        <h1 className="md:text-lg font-xsbold">Notifications</h1>
                                        <div className="flex items-center gap-5">
                                            <CloseIcon onClick={() => handleButtonClick(false)} className="cursor-pointer" />
                                        </div>
                                    </div>

                                    <div className='lg:max-h-[30rem] md:max-h-[27rem] max-h-[25rem] overflow-y-auto whitespace-nowrap w-full scrollbar-hide md:pt-20 pt-10'>
                                        {
                                            !isLoading ? (
                                                data?.data.length ?
                                                    data?.data
                                                        .map(({ read, notification, uuid }: NotificationProps) => <NotficationCard key={uuid} read={read} notification={notification} />)
                                                    : (
                                                        <div className='text-center'>
                                                            <img className='w-10 mx-auto my-5' src={Img} alt="dappy" />
                                                            <p>No Notification</p>
                                                        </div>
                                                    )
                                            ) : (
                                                <div className="w-full mx-auto flex justify-center items-center my-24">
                                                    <PuffLoader loading color="#0052CC" />
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
}

export default NotificationModal