import jwt from 'jwt-decode';

const setToken = (token: string) => {
  localStorage.setItem('access_token', token);                            
};

const setRefreshToken = (token: string) => {
  localStorage.setItem('refreshToken', token);                                
};

const getToken = () => {
  return localStorage.getItem('access_token');                                   
};

const getRefreshToken = () => {
  return localStorage.getItem('refreshToken');                              
};

const getDecodedJwt = () => {
  try {
    const token = getToken();
    return jwt<{exp: number, user_id: number}>(token!);
  } catch (e) {
    return {};
  }
};

const removeToken = () => {
  localStorage.removeItem('access_token');
  localStorage.clear();
};

const logOut = () => {
  removeToken();
  window.location.replace('/login');
};

const isAuthenticated = () => {
  try {
    const decodedToken: any = getDecodedJwt();

    const { exp } = decodedToken;
    const currentTime = Date.now() / 1000;
   
    return exp > currentTime;
  } catch (e) {
    return false;
  }
};



const Auth = {
  isAuthenticated,
  getDecodedJwt,
  setToken,
  getToken,
  setRefreshToken,
  getRefreshToken,
  removeToken,
  logOut,
};

export default Auth;
