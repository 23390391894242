import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { publicRoutes, authProtectedRoutes, authRoutes } from './index';
import { AuthLayout, DashboardLayout, Layout } from 'layouts/Layouts';
import PageNotFound from 'pages/404'
import Auth from 'utils/Auth';
import { DASHBOARD, LOGIN } from 'constants/path';

type TRoutes = {
  path: string;
  component: React.FC;
  title?: string,
  quotes?: string[]
};

const RouteComponent = () => {
  const status = localStorage.getItem('status') || null

  return (
    <Routes>
      {authProtectedRoutes.map((route: TRoutes) => {
        const { path, component: Component, title } = route;

        return (
          <Route
            key={path}
            path={path}
            element={
              (Auth.isAuthenticated()) ?
                <DashboardLayout title={title}>
                  <Component />
                </DashboardLayout> 
                :
                <Navigate to={LOGIN} />
            }
          />
        );
      })}

      {publicRoutes.map((route: TRoutes) => {
        const { path, component: Component } = route;

        return (
          <Route
            key={path}
            path={path}
            element={
              <Layout>
                <Component />
              </Layout>
            }
          />
        );
      })}

      {authRoutes.map((route: TRoutes) => {
        const { path, component: Component, quotes } = route;
        
        return (
            <Route
              key={path}
              path={path}
              element={
                (Auth.isAuthenticated() && status === 'completed') ?
                <Navigate to={DASHBOARD} />
                : 
                <AuthLayout quotes={quotes}>
                  <Component />
                </AuthLayout>
              }
              />
        );
      })}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default RouteComponent;
