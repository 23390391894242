import React from 'react'
import withScrollTop from 'components/WithScrollTop';
import Button from 'components/Button';
import { useNavigate } from 'react-router-dom';

import arrow from 'assets/icons/arrow.svg';
import NotFoundImg from 'assets/images/NotFoundImg.svg';
import Img404 from 'assets/images/404.svg';
import SEOHelmet from 'components/Helmet';


const PageNotFound: React.FC = () => {
  const navigate = useNavigate()
  
  return (
    <div className='bg-[#042954] w-full h-[100vh]'>
      <SEOHelmet  
        title="Page Not Found"
        description='Daple enables class reps to send info and reminders to students easily.'
        keywords='Daple, students, courses, class-rep, time management,'
      />
      <div className='relative md:p-20 px-10 mx-auto max-w-full'>
        <div className='text-white pt-20'>
          <h1 className='text-[60px] lg:text-[100px] font-xsbold uppercase'>Oops!</h1>
          <p className='text-white/80 md:text-md text-smd mb-20'>We could not find the page you are looking for</p>
        </div>
        <Button classProp='flex items-center gap-2 md:gap-3 py-0 px-8 pl-6 mt-20 border-white text-white' handleClick={() => navigate(-1)} isSecondary>
          <img className="p-2 w-[50px] rotate-180" src={arrow} alt="right-arrow" />
          Go Back
        </Button>
      </div>
        <img className='absolute opacity-30 md:w-80 lg:w-auto top-0 left-0' src={Img404} alt="not-found" />
        <img className='absolute hidden lg:block opacity-60 right-20 lg:inset-y-20' src={NotFoundImg} alt="not-found" />
        <img className='absolute block lg:hidden opacity-30 w-52 md:w-80 right-0 top-48 md:top-20' src={NotFoundImg} alt="not-found" />
        <img className='absolute opacity-30 md:w-80 lg:w-auto bottom-0 right-0' src={Img404} alt="not-found" />
    </div>
  )
}

export default withScrollTop(PageNotFound)