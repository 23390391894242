import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { dashboardLinks } from 'data';
import { DASHBOARD } from 'constants/path';
import LogoutModal from './Logout/LogoutModal';

import { ReactComponent as Logo } from 'assets/icons/dashboard/logo.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/dashboard/logoutIcon.svg';
import { ReactComponent as OpenMenu } from 'assets/icons/dashboard/openmenu.svg';

const Sidebar = ({ isOpen, handleClick }: { isOpen: boolean, handleClick: (val: boolean) => void }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <aside className={styles.container}>
      <>
        <div className={styles.section}>
          <div>
            <Link to={DASHBOARD}>
              <Logo className="w-20" />
            </Link>
            <OpenMenu className={styles.expandSidebar} />
            <div className={styles.divider}></div>
          </div>
          <div className={styles.linksContainer}>
            <div>
              {dashboardLinks.map((links) => {
                const { icon: Icon, route, label } = links;
                return (
                  <NavLink className="bg-[white] mb-10" key={route} to={route}>
                    {({ isActive }) =>
                      isActive ?
                        <div className={`${styles.tab} ${styles.activeTab}`}>
                          <Icon className={styles.activeIcon} />
                          <p className={styles.label}>{label}</p>
                        </div>
                        :
                        <div className={styles.tab}>
                          <Icon className={styles.inactiveIcon} />
                          <p className={styles.label}>{label}</p>
                        </div>
                    }
                  </NavLink>
                );
              })}
            </div>
            <div className='w-full mt-3'>
              <div className={styles.divider}></div>
              <button className={styles.logoutBtn} onClick={() => setShowModal(true)}>
                <LogoutIcon className={styles.logoutIcon} />
                <p className='text-xxs'>Exit</p>
              </button>
            </div>
          </div>
        </div>
        <div className={`${styles.mobilesection} ${isOpen ? "ml-0" : "-ml-[100%]"}`}>
          <div>
            <Link onClick={() => handleClick(false)} to={DASHBOARD}>
              <Logo className="w-20 mx-auto" />
            </Link>
            <OpenMenu className={styles.expandSidebar} />
            <div className={styles.divider}></div>
          </div>
          <div className={styles.linksContainer}>
            <div className='w-full'>
              {dashboardLinks.map((links) => {
                const { icon: Icon, route, label } = links;
                return (
                  <NavLink className="bg-[white] mb-10" key={route} to={route}>
                    {({ isActive }) =>
                      isActive ?
                        <div onClick={() => handleClick(false)} className={`${styles.mobiletab} ${styles.activeTab}`}>
                          <Icon className={styles.activeIcon} />
                          <p className={styles.label}>{label}</p>
                        </div>
                        :
                        <div onClick={() => handleClick(false)} className={styles.mobiletab}>
                          <Icon className={styles.inactiveIcon} />
                          <p className={styles.label}>{label}</p>
                        </div>
                    }
                  </NavLink>
                );
              })}
            </div>
            <div className='w-full mt-3'>
              <div className={styles.divider}></div>
              <button className={styles.mobiletab} onClick={() => setShowModal(true)}>
                <LogoutIcon className={styles.logoutIcon} />
                <p className={styles.label}>Exit</p>
              </button>
            </div>
          </div>
        </div>
      </>

      <LogoutModal showModal={showModal} handleButtonClick={() => setShowModal(false)} />
    </aside>
  );
};

const styles = {
  container: 'fixed h-screen z-30 w-4/5 md:w-auto',
  section: 'relative px-5 text-center py-5 hidden md:block h-full bg-primary ',
  mobilesection: 'transition transition-[margin] duration-300 relative px-5 text-center py-5 md:hidden block h-full w-full bg-primary ',
  activeIcon: 'md:mx-auto mb-2 fill-primary stroke-primary',
  inactiveIcon: 'md:mx-auto mb-2',
  expandSidebar: 'mx-auto cursor-pointer mt-2 hidden md:block',
  logoutIcon: ' mb-2 md:mx-auto fill-white stroke-white hover:fill-primary hover:stroke-primary',
  linksContainer: "flex flex-col items-center h-full",
  label: "text-sm md:text-xxs md:pb-0 pb-1",
  tab: "mt-3 cursor-pointer text-center text-white p-2 rounded-lg transition duration-300 hover:bg-white/80 hover:text-primary hover:drop-shadow-md hover:fill-primary hover:stroke-primary fill-white stroke-white",
  mobiletab: "mt-3 flex items-center gap-5 w-full cursor-pointer text-center text-white p-2 pb-1 rounded-lg transition duration-300 hover:bg-white/80 hover:text-primary hover:drop-shadow-md hover:fill-primary hover:stroke-primary fill-white stroke-white",
  logoutBtn: "w-full cursor-pointer text-center text-white p-2 rounded-lg transition duration-300 hover:bg-white/80 hover:text-primary hover:drop-shadow-md hover:fill-primary hover:stroke-primary fill-white stroke-white",
  activeTab: "bg-white !text-primary",
  divider: "my-2 border-b-2 border-white/30 w-full",
};

export default Sidebar;
