import React from "react";
import Button from "components/Button";
import { Link } from "react-router-dom";
import Auth from "utils/Auth";
import { CONTACT, COURSES, DASHBOARD, FAQ, SIGNUP, PRIVACYPOLICY } from "constants/path";

import logo from "assets/images/whiteLogo.svg";
import phone from "assets/icons/tel.svg";
import email from "assets/icons/email.svg";
import instagram from "assets/icons/instagram.svg";
import twitter from "assets/icons/twitter.svg";
import linkedin from "assets/icons/linkedin.svg";
import caret from "assets/icons/caret.svg";
import arrow from "assets/icons/whiteArrow.svg";

const Footer: React.FC = () => {
  const year = new Date().getFullYear();

  const scrollToTop = () => {
    document.documentElement.scrollTop = 0;
  };

  return (
    <footer className={styles.section}>
      <div className={styles.maxWidth}>
        <div className={styles.gridContainer}>
          <div className={styles.firstCol}>
            <img src={logo} alt="logo" />
            <p className="my-10">
              This website and its content are copyright © {year} by Syrux. All rights reserved. Use of this website is
              subject to our Terms of Service and Privacy Policy. By using this website, you agree to these terms and
              conditions.
            </p>

            <div
              className={styles.contact}
              onClick={() => (window.location.href = `tel:${process.env.REACT_APP_DAPLE_CONTACT_PHONE}`)}
            >
              <img className="mr-3 mb-4" src={phone} alt="phone" /> {process.env.REACT_APP_DAPLE_CONTACT_PHONE}
            </div>
            <div
              className={styles.contact}
              onClick={() => (window.location.href = `mailto:${process.env.REACT_APP_DAPLE_CONTACT_EMAIL}`)}
            >
              <img className="mr-3" src={email} alt="email" /> {process.env.REACT_APP_DAPLE_CONTACT_EMAIL}
            </div>
          </div>

          <div className="">
            <h3 className={styles.title}>User Links</h3>
            <div className={styles.line}></div>
            <div>
              <p className={styles.linkContainer}>
                <Link to={DASHBOARD} className={styles.link}>
                  Home
                </Link>
              </p>
              <p className={styles.linkContainer}>
                <Link to={COURSES} className={styles.link}>
                  Courses
                </Link>
              </p>
              <p className={styles.linkContainer}>
                <Link to={FAQ} className={styles.link}>
                  FAQs
                </Link>
              </p>
              <p className={styles.linkContainer}>
                <Link to={CONTACT} className={styles.link}>
                  Contact Us
                </Link>
              </p>
              <p className={styles.linkContainer}>
                <Link to={PRIVACYPOLICY} target="_blank" className={styles.link}>
                  Privacy Policy
                </Link>
              </p>
            </div>
          </div>

          <div className={styles.thirdCol}>
            <h3 className={styles.title}>Sign Up</h3>
            <div className={styles.line}></div>
            <p className="mb-5">
              Join the community of successful students with Daple. Sign up now and stay on top of your schedule with
              instant notifications of class changes and reminders for deadlines.
            </p>
            {Auth.isAuthenticated() ? (
              <Link to={DASHBOARD}>
                <Button classProp={styles.btn}>
                  Go to dashboard
                  <img className="p-2 w-[35px] md:w-[50px]" src={arrow} alt="right-arrow" />
                </Button>
              </Link>
            ) : (
              <Link to={SIGNUP}>
                <Button classProp={styles.btn}>
                  Get Started
                  <img className="p-2 w-[35px] md:w-[50px]" src={arrow} alt="right-arrow" />
                </Button>
              </Link>
            )}
          </div>
        </div>

        <div className="mt-5 flex">
          <div className="w-[100%]">
            <div className="border mt-3"></div>
          </div>
          <img
            onClick={() => window.open(process.env.REACT_APP_DAPLE_TWITTER, "_blank")}
            className={styles.social}
            src={twitter}
            alt="twitter"
          />
          <div className="w-[40%] md:w-[20%] p-0">
            <div className="border mt-3"></div>
          </div>
          <img
            onClick={() => window.open(process.env.REACT_APP_DAPLE_INSTAGRAM, "_blank")}
            className={styles.social}
            src={instagram}
            alt="instagram"
          />
          <div className="w-[40%] md:w-[20%] p-0">
            <div className="border mt-3"></div>
          </div>
          <img
            onClick={() => window.open(process.env.REACT_APP_DAPLE_LINKEDIN, "_blank")}
            className={styles.social}
            src={linkedin}
            alt="linkedin"
          />
          <div className="w-[100%]">
            <div className="border mt-3"></div>
          </div>
        </div>
        <div>
          <div className="">
            <img onClick={scrollToTop} className={styles.caret} src={caret} alt="caret" />
          </div>
        </div>
        <p className={styles.cp}>Developed by Syrux, {year}</p>
      </div>
    </footer>
  );
};

const styles = {
  section: "md:px-20 px-5 bg-[#152444] text-white text-xs pt-20 pb-10 w-full",
  maxWidth: "max-w-full mx-auto w-full",
  gridContainer: "grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-20",
  firstCol: "",
  contact:
    "cursor-pointer flex hover:text-primary transition linear delay-2s w-[auto] hover:transition linear delay-2s",
  title: "md:text-mlg text-md font-light border-b-1",
  line: "border border-b-1 border-white w-[50px] rounded mt-3 mb-12",
  link: "cursor-pointer hover:text-primary transition linear delay-2s w-[auto] hover:transition linear delay-2s",
  linkContainer: "mb-5",
  thirdCol: "",
  caret: "bg-[#1A9CFF] md:ml-[95.7%] ml-[85%] rounded my-5 p-2 cursor-pointer",
  cp: "text-center text-xs",
  btn: "!py-0 md:py-1 py-2 md:px-8 md:pr-6 px-4 mt-5 text-sm flex items-center gap-2 md:gap-3 lg:mx-0 mx-auto",
  social: "cursor-pointer mx-1 transition duration-300 hover:scale-110",
};

export default Footer;
