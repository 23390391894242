import React from 'react'
import { Link } from 'react-router-dom'
import { PROFILE } from 'constants/path'
import { GetStudentProfile } from 'services/Students'
import { HeaderProps } from 'types/Types'
import { SyncLoader } from 'react-spinners';
import useUser from 'hooks/useUser'

import { ReactComponent as NotificationIcon } from 'assets/icons/dashboard/notificationIcon.svg'
import menu from 'assets/icons/menu.svg';

const Header: React.FC<HeaderProps> = ({ title, handleShowModal, isOpen, handleClick }) => {
  const { data, isLoading } = GetStudentProfile()

  const details = data?.data

  const { name, initials } = useUser()

  return (
    <nav className={styles.section}>
      <div className={styles.left}>
        <img onClick={() => handleClick(true)} className="cursor-pointer block md:hidden" src={menu} alt="menu" />
        <h1 className={styles.title}>{title}</h1>
      </div>

      <div className={styles.linkContainer}>
        <div className='relative'>
          <div className={styles.notifydot}>
          </div>
          <NotificationIcon onClick={() => handleShowModal(true)} className={styles.notify} />
        </div>
        <Link to={PROFILE} className={styles.linkToProfile}>
          {!isLoading ? (
            <div className={styles.nameContainer}>
              <h4 className={styles.name}>{name}</h4>
              <p className={styles.dept}>{details?.department.short_name} {details?.level}L</p>
            </div>) : <span className="hidden md:block"> <SyncLoader loading color="#0052CC" size={10} speedMultiplier={0.4} /> </span>
          }
          <div className={styles.initials}>{initials}</div>
        </Link>
      </div>
    </nav>
  )
}

const styles = {
  section: 'max-w-full  ml-auto w-full fixed 2xl:relative md:w-11/12 right-0 backdrop-blur-lg z-10 pr-10 md:pl-20 pl-5 pb-2 pt-6 flex items-center justify-between',
  title: 'text-dashboard-black md:text-mlg font-xsbold',
  left: 'flex items-center gap-5',
  linkContainer: 'flex items-center gap-3 md:gap-10',
  notify: 'scale-125 hover:scale-150 transition linear duration-[0.3s] cursor-pointer',
  linkToProfile: 'md:flex items-center gap-5 cursor-pointer',
  initials: 'w-10 h-10 rounded-full mb-1 md:mb-0 flex items-center justify-center bg-primary text-white text-xs font-xbold',
  nameContainer: 'text-right hidden md:block',
  name: 'font-xbold',
  dept: 'text-xs',
  notifydot: "absolute right-3 top-1 z-10 bg-primary w-2 h-2 rounded-full",
}

export default Header