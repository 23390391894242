import React from 'react';
import { Link } from 'react-router-dom';
import { TextCarousel } from 'components/Carousel';
import { SwiperSlide } from 'swiper/react';

import blackGirl from '../assets/images/blackGirl.svg';
import logo from '../assets/images/logo.svg';
import quote from '../assets/icons/smallQuote.svg';

type AuthWrapperType = {
  children: React.ReactNode;
  quotes: string[]
}

const AuthWrapper: React.FC<AuthWrapperType> = ({ children, quotes }) => {
  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <div className={styles.leftSection}>
          <div className={styles.inner}>
            <Link to="/">
              <img className="w-16" src={logo} alt="logo" />
            </Link>
            <div>
              <img className={styles.blackGirlImg} src={blackGirl} alt="black girl smiling" />
            </div>
            <div className={styles.note}>
              <img className={styles.quote} src={quote} alt="quote" />
                <TextCarousel>
                    {quotes.map(quote => (
                      <SwiperSlide key={quote}>
                        <p className={styles.message}>
                          {quote}
                        </p>
                      </SwiperSlide>
                    ))}
                </TextCarousel>
            </div>
          </div>
        </div>

        <div className="lg:flex-1 h-full leading">{children}</div>
      </div>
    </div>
  );
};

const styles = {
  container: 'bg-secondary',
  section: 'lg:flex items-start gap-20 lg:px-20 md:px-10 px-5 py-8 max-w-full mx-auto h-[100vh] overflow-auto scrollbar-hide',
  blackGirlImg: 'w-24 mx-auto my-10 2xl:w-52',
  leftSection: 'shadow md:w-[30%] w-full',
  inner:
    'fixed lg:flex lg:flex-col lg:justify-between lg:block hidden bg-white p-5 2xl:py-10 2xl:mt-16 rounded-[15px] 2xl:max-w-[20%] md:max-w-[30%] w-full h-[90%] auth-shadow',
  note: 'bg-primary text-white p-5 rounded-lg text-center',
  quote: 'mx-auto',
  message: 'my-4 leading-6 h-3/4 bg-primary',
};

export default AuthWrapper;
