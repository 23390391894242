import { ActivityCardType, CourseCardType, CourseType } from 'types/Types';
import relativeTimePlugin from 'dayjs/plugin/relativeTime'
import dayjs from 'dayjs'
import isTomorrow from 'dayjs/plugin/isTomorrow';

dayjs.extend(isTomorrow);

export const getTime = (time: string) => {
  let formattedTime = dayjs(time).format("hh:mm A")
  return formattedTime;
}

export const getRelativeTime = (time: string) => {
  dayjs.extend(relativeTimePlugin)

  let relativeTime = dayjs(time).fromNow()

  return relativeTime;
}

export const parseDay = (value: number) => {
  let day;

  switch (value) {
    case 1:
      day = "Mon";
      break;
    case 2:
      day = "Tue";
      break;
    case 3:
      day = "Wed";
      break;
    case 4:
      day = "Thur";
      break;
    case 5:
      day = "Fri";
      break;
  
    default: day = "Mon"
      break;
  }

  return day;
}

export const parseValidationError = (err: any) => {
  const obj: {
    [key: string]: boolean;
  } = {};

  Object.keys(err).forEach((key: string) => {
    obj[key] = err[key][0];
  });
  return obj;
};

export const parseToSelect = (
  values: { name: string; code: string; uuid?: string; id: string | number; short_name?: string }[] = []
) => {
  if (values.length === 0) {
    return [];
  }
  return values.map((item) => {
    return {
      label: item.name || item.code,
      value: item.uuid || item.name,
      key: item.short_name || item.id,
    };
  });
};

export const extractId = (
  values: {
    uuid: string;
  }[] = []
) => {
  if (values.length === 0) {
    return [];
  }
  return values.map((item) => {
    return item.uuid;
  });
};

export const prefixZero = (value: string | number) => {
  return value.toString().padStart(11, '0');
};

export const profileTabs = [
  { label: 'Primary', key: 1 },
  { label: 'Student', key: 2 },
];

export const passwordTabs = [
  { label: 'Password', key: 1 },
  { label: 'Security', key: 2 },
];

export const upcomingClassesSample: CourseCardType[] = [
  {
    uuid: '21',
    colour: '#4CAF50',
    status: 'holding',
    lecturer_names: ['Prof Obe', 'Prof Adewalee', 'Dr Sarunmi'],
    code: 'CSC 409',
    title: 'Computer Graphics',
    venue: 'LT 1',
    lecture_time: '10:00 am',
  },
  {
    uuid: '22',
    colour: '#D7009B',
    status: 'holding',
    lecturer_names: ['Dr Sarunmi'],
    code: 'CSC 401',
    title: 'Database System',
    venue: 'Middle Lab',
    lecture_time: '08:00 am',
  },
  {
    uuid: '23',
    colour: '#EE9CDA',
    status: 'cancelled',
    lecturer_names: ['Prof Obe', 'Prof Adewalee', 'Dr Sarunmi'],
    code: 'CSC 409',
    title: 'Software Engineering',
    venue: 'ETF',
    lecture_time: '12:00 pm',
  },
  {
    uuid: '24',
    colour: '#EE9CDA',
    status: 'cancelled',
    lecturer_names: ['Prof Obe', 'Prof Adewalee', 'Dr Sarunmi'],
    code: 'CSC 409',
    title: 'Software Engineering',
    venue: 'ETF',
    lecture_time: '12:00 pm',
  },
  {
    uuid: '25',
    colour: '#EE9CDA',
    status: 'cancelled',
    lecturer_names: ['Prof Obe', 'Prof Adewalee', 'Dr Sarunmi'],
    code: 'CSC 409',
    title: 'Software Engineering',
    venue: 'ETF',
    lecture_time: '12:00 pm',
  },
];

export const sampleFeeds = [
  {
    uuid: '12dw34fr3rer2',
    tag: '#CSC453',
    time: '09:45 am',
    content: 'Prepare for CSC 500 test tomorrow. Read your books and materials. Goodluck',
    date_created: 'Computer Graphics',
    venue: 'LT 1',
    created_by: 'Josh',
    type: '',
  },
  {
    uuid: 'je37f5beo33',
    tag: '#CSC401',
    time: '11:45 am',
    content: 'Prepare for CSC 500 test tomorrow. Read your books and materials. Goodluck',
    date_created: 'Computer Graphics',
    venue: 'New SOC',
    created_by: 'James',
    type: '',
  },
  {
    uuid: '9sdjo47fjwd0s',
    tag: '#Everyone',
    time: '08:00 am',
    content: 'Prepare for CSC 500 test tomorrow. Read your books and materials. Goodluck',
    date_created: 'Computer Graphics',
    venue: 'Small LT',
    created_by: 'Leah',
    type: '',
  },
];

export const typeColour = (type: string) => {
  let colour;

  switch (type) {
    case 'Test':
      colour = '#4CAF50';
      break;
    case 'Examination':
      colour = '#d7009b';
      break;
    case 'Assignment':
      colour = '#9747ff';
      break;
    case 'Practical':
      colour = 'brown';
      break;
    case 'Annonucement':
      colour = '#ffc164';
      break;
      
    default:
      colour = '#4CAF50';
      break;
  }
  return colour;
}

export const sampleActivity: ActivityCardType[] = [
  {
    id: '5sjkeis',
    colour: '#4CAF50',
    type: 'Test',
    content: 'Lecturer said something about the test. Read the whole book',
    lecturers: ['Prof Obe', 'Prof Adewalee', 'Dr Sarunmi'],
    course_code: 'CSC 409',
    course_title: 'Computer Graphics',
    day: 'Mon • 10/01/2020',
    tag: '',
    venue: 'New 3-in-1 C',
    time: '10:00 am',
  },
  {
    id: '37sndsd',
    colour: '#9747ff',
    type: 'Assignment',
    content: 'This assignment is due by tommorrow. Hope youve completed it',
    lecturers: ['Prof Obe', 'Prof Adewalee', 'Dr Sarunmi'],
    course_code: 'CSC 413',
    course_title: 'Computer Graphics',
    day: 'Mon • 10/01/2020',
    tag: '',
    venue: 'ETF',
    time: '08:00 am',
  },
  {
    id: '83uhsd',
    colour: '#ffc164',
    type: 'Exam',
    content: 'Lecturer said something about the test. Read the whole book',
    lecturers: ['Prof Obe', 'Prof Adewalee', 'Dr Sarunmi'],
    course_code: 'CSC 449',
    course_title: 'Computer Graphics',
    day: 'Mon • 10/01/2020',
    tag: '',
    venue: 'LT 1',
    time: '10:00 am',
  },
  {
    id: '5sjkeis',
    colour: '#4CAF50',
    type: 'Test',
    content: 'Lecturer said something about the test. Read the whole book',
    lecturers: ['Prof Obe', 'Prof Adewalee', 'Dr Sarunmi'],
    course_code: 'CSC 409',
    course_title: 'Computer Graphics',
    day: 'Mon • 10/01/2020',
    tag: '',
    venue: 'New 3-in-1 C',
    time: '10:00 am',
  },
  {
    id: '37sndsd',
    colour: '#9747ff',
    type: 'Assignment',
    content: 'This assignment is due by tommorrow. Hope youve completed it',
    lecturers: ['Prof Obe', 'Prof Adewalee', 'Dr Sarunmi'],
    course_code: 'CSC 413',
    course_title: 'Computer Graphics',
    day: 'Mon • 10/01/2020',
    tag: '',
    venue: 'ETF',
    time: '08:00 am',
  },
  {
    id: '83uhsd',
    colour: '#ffc164',
    type: 'Exam',
    content: 'Lecturer said something about the test. Read the whole book',
    lecturers: ['Prof Obe', 'Prof Adewalee', 'Dr Sarunmi'],
    course_code: 'CSC 449',
    course_title: 'Computer Graphics',
    day: 'Mon • 10/01/2020',
    tag: '',
    venue: 'LT 1',
    time: '10:00 am',
  },
  {
    id: '5sjkeis',
    colour: '#4CAF50',
    type: 'Test',
    content: 'Lecturer said something about the test. Read the whole book',
    lecturers: ['Prof Obe', 'Prof Adewalee', 'Dr Sarunmi'],
    course_code: 'CSC 409',
    course_title: 'Computer Graphics',
    day: 'Mon • 10/01/2020',
    tag: '',
    venue: 'New 3-in-1 C',
    time: '10:00 am',
  },
  {
    id: '37sndsd',
    colour: '#9747ff',
    type: 'Assignment',
    content: 'This assignment is due by tommorrow. Hope youve completed it',
    lecturers: ['Prof Obe', 'Prof Adewalee', 'Dr Sarunmi'],
    course_code: 'CSC 413',
    course_title: 'Computer Graphics',
    day: 'Mon • 10/01/2020',
    tag: '',
    venue: 'ETF',
    time: '08:00 am',
  },
  {
    id: '83uhsd',
    colour: '#ffc164',
    type: 'Exam',
    content: 'Lecturer said something about the test. Read the whole book',
    lecturers: ['Prof Obe', 'Prof Adewalee', 'Dr Sarunmi'],
    course_code: 'CSC 449',
    course_title: 'Computer Graphics',
    day: 'Mon • 10/01/2020',
    tag: '',
    venue: 'LT 1',
    time: '10:00 am',
  },
];

export const blankAboutCourse = {
    colour: '',
    code: '',
    lecturer_names: [''],
    class_rep: { user_account: { first_name: '', last_name: '', phone_number: '' } },
    description: '',
    title: '',
    uuid: '',
    venue: '',
    lecture_time: '',
    day: '',
}

export const days = [
  { value: 'Monday', label: 'Mon', key: 'mon' },
  { value: 'Tuesday', label: 'Tue', key: 'tue' },
  { value: 'Wednesday', label: 'Wed', key: 'wed' },
  { value: 'Thursday', label: 'Thur', key: 'thur' },
  { value: 'Friday', label: 'Fri', key: 'fri' },
  { value: 'Saturday', label: 'Sat', key: 'sat' },
];


export const groupCourses = (courses: CourseType[]) => {
  const response: { title: string; data: CourseType[] }[] = [
    { title: 'Tomorrow', data: [] },
    { title: 'Later', data: [] },
  ];

  for (const course of courses) {
    const date = course.lecture_time;
    if (dayjs(date).isTomorrow()) {
      const id = response.findIndex((item) => item.title === 'Tomorrow');
      if (id >= 0) {
        response[id].data.push(course);
      }
    } else {
      const id = response.findIndex((item) => item.title === 'Later');
      if (id >= 0) {
        response[id].data.push(course);
      }
    }
  }

  return response;
};