import React, { ReactElement } from "react";

import { Swiper, SwiperProps } from "swiper/react";
import { Pagination, Navigation, EffectFade } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";


interface CarouselProps extends SwiperProps {
  children: ReactElement[]
}

export default function Carousel({children}: CarouselProps) {
  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        {children}
      </Swiper>
    </>
  );
}

export const TextCarousel = ({children}: CarouselProps) => {
  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={30}
      modules={[EffectFade, Navigation]}
      effect='fade'
      loop={true}
      className="text-swiper"
      autoplay={{
        delay: 2000,
        disableOnInteraction: false
      }}
      navigation={true}
      
    >
      {children}
    </Swiper>
  )
}
