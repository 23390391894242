import React from 'react';
import AuthWrapper from './AuthWrapper';
import DashboardWrapper from './DashboardWrapper';

import Footer from './Footer';
import Header from './Header';

interface ILayout {
  children: React.ReactNode;
  title?: string;
  quotes?: string[]
}

export const DashboardLayout = ({ children, title = "" }: ILayout) => {
  return <DashboardWrapper title={title}>{children}</DashboardWrapper>
}

export const AuthLayout = ({ children, quotes = [] }: ILayout) => {
  return <AuthWrapper quotes={quotes}>{children}</AuthWrapper>;
};

export const Layout = ({ children }: ILayout) => {
  return (
    <>
      <Header />
      <div className="pt-16 md:pt-20">{children}</div>
      <Footer />
    </>
  );
};
