import React from 'react'
import { getRelativeTime } from 'utils/Helpers'


const NotficationCard = ({ read, notification }: any) => {
    const { body, title, date_created } = notification;
  return (
    <div className={`${styles.container} ${read ? "bg-white" : "bg-primary/10"}`}>
        <div className={styles.section}>
            <div className={styles.notify}>
                <p className={styles.titlelg}>{title}</p>
                <p className={styles.notificationlg}>{body}</p>
            </div>
        </div>
        <div className='text-center'>
            <div className='p-1 bg-primary rounded-full w-1 mx-auto mb-3'></div>
            <p className='text-xxs text-gray'>{getRelativeTime(date_created)}</p>
        </div>
    </div>
  )
}

const styles = {
    container: "flex justify-between items-center lg:gap-10 gap-5 py-5 md:px-10 px-5 my-5 rounded-xl",
    section: "flex justify-start items-center lg:gap-10 md:gap-5 gap-3",
    title: "block md:hidden font-xbold mb-2 md:text-sm text-xs",
    titlelg: "font-xbold mb-2 md:text-sm text-xs truncate lg:w-auto md:w-72 w-36",
    img: "w-5 md:w-auto",
    notify: "",
    notification: "block md:hidden md:text-sm text-xs",
    notificationlg: "md:text-sm text-xs truncate lg:w-auto md:w-72 w-36",
}

export default NotficationCard