import React from 'react'
import { ModalType } from "types/Types";
import Logout from 'pages/Logout'

const LogoutModal = ({ showModal, handleButtonClick }: ModalType) => {

    return (
        <>
            {showModal ? (
                <div className="w-full h-full">
                    <div className="fixed inset-0 z-50 overflow-y-auto bg-red filter backdrop-blur-md">
                        <div className="fixed inset-0 w-full h-full bg-black opacity-60"></div>
                        <div className="flex items-center min-h-screen px-4 py-8">
                            <div className="relative w-auto md:p-4 mx-auto rounded-3xl shadow-lg">
                                <Logout handleButtonClick={handleButtonClick} />
                          </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
}

export default LogoutModal