import Header from "components/Dashboard/Header";
import NotificationModal from "components/Dashboard/Notification/NotificationModal";
import Sidebar from "components/Dashboard/Sidebar";
import React, { useState } from "react";

type DType = {
  children: React.ReactNode;
  title: string;
};

const DashboardWrapper = ({ children, title }: DType) => {
  const [showModal, setShowModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="bg-secondary h-full">
      <div className="mx-auto w-full">
        <div className="h-full z-20">
          <Sidebar handleClick={(val) => setIsOpen(val)} isOpen={isOpen} />
        </div>
        <main className={`md:px-0 px-5 relative ${isOpen ? "z-auto" : "z-30 md:z-20"}`}>
          <Header
            isOpen={isOpen}
            handleClick={(val) => setIsOpen(val)}
            title={title}
            handleShowModal={() => setShowModal(true)}
          />
          <div className="pt-24 md:pt-28 pb-20 z-0 md:w-11/12 md:pr-10 md:pl-20 md:ml-auto h-screen overflow-scroll scrollbar-hide">
            {children}
          </div>
        </main>
        <NotificationModal showModal={showModal} handleButtonClick={() => setShowModal(false)} />
      </div>
    </div>
  );
};

export default DashboardWrapper;
