export const LOGIN = "/login"
export const SIGNUP = "/sign-up"
export const ONBOARD = '/sign-up/onboard'
export const FORGOTPASSWORD = '/forgot-password'
export const RESETPASSWORD = '/reset-password'

export const HOME = "/"
export const FAQ = "/faq"
export const CONTACT = "/contact"
export const PRIVACYPOLICY = "/privacy-policy"

export const DASHBOARD = '/dashboard'
export const COURSES = '/courses'
export const EDITCOURSES = '/courses/edit-course'
export const ABOUTCOURSES = '/courses/:code'
export const ACTIVITIES = '/activities'
export const NEWCOURSE = '/courses/new-course'
export const NEWACTIVITY = '/courses/new-activity'
export const REMOVECOURSE = '/courses/remove-course'
export const PROFILE = '/profile'
export const SECURITY = 'security'
export const VERIFYACCOUNT = 'verify-account'
export const SUPPORT = 'support'
export const LOGOUT = '/logout'