import React, { ReactNode } from 'react'

type TButton = {
  children: String | ReactNode,
  handleClick?: () => void;
  classProp?: string,
  isSecondary?: boolean
  type?: "button" | "submit" | "reset",
  isDisabled?: boolean
}

const Button = ({ children, handleClick, isSecondary = false, classProp, type, isDisabled = false }: TButton) => {
  return <button 
            className={`border-2 border-primary py-3 px-16 rounded-lg ${isSecondary ? "text-primary bg-[transparent] hover:filter hover:brightness-10" : "text-white bg-primary"} font-xbold text-xs cursor-pointer drop-shadow-md hover:filter hover:brightness-75 duration-300 ${isDisabled && "cursor-not-allowed bg-primary/30 border-2 border-primary/30"} ${classProp}`} 
            onClick={handleClick}
            type={type}
            disabled={isDisabled}
          > 
              {children} 
          </button>
}

export default Button