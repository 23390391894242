import { useMutation, useQuery } from 'react-query';
import { Api } from 'utils/Api';


  export const GetStudentProfile = () => {
    return useQuery('profile', () => Api.get('/student/profile').then((res) => res.data));
  };
 
  export const UpdateStudentProfile = () => {
    return useMutation((data) => {
        return Api.put('/student/profile', data);
    });
  };

  export const GetStudentCourses = () => {
    return useQuery('my-courses', () => Api.get('/student/courses').then((res) => res.data));
  };
  
  export const GetTodayCourses = () => {
    return useQuery('my-courses', () => Api.get('/student/courses?filter=today').then((res) => res.data));
  };
  
  export const GetThisWeekCourses = () => {
    return useQuery('my-courses', () => Api.get('/student/courses?filter=this_week').then((res) => res.data));
  };
  
  export const GetNotifications = () => {
    return useQuery('notifications', () => Api.get('/notifications').then((res) => res.data));
  };