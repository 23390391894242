const setUser = (userObject: string) => {
    localStorage.setItem('user', userObject);                            
} 

const getUser = () => {
    return JSON.parse(localStorage.getItem('user')!);
}


const User = {
    setUser,
    getUser,
}

export default User;